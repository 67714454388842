import 'core-js/stable'
import 'regenerator-runtime/runtime'
import "~/stylesheets/tailwindcss.scss";
import "jquery";
import "popper.js"
import "local-time";
import "@viewabo/fontawesome-pro/js/all";
import "@rails/ujs";

import { Application } from '@hotwired/stimulus';
import * as Turbo from "@hotwired/turbo";
import * as ActiveStorage from "@rails/activestorage";
import { registerControllers } from 'stimulus-vite-helpers';

import.meta.glob('../images/**/*.{png,jpg,jpeg,gif,svg,ico,webp}')

import "~/stylesheets/video_session.scss";

import { defineAsyncComponent } from 'vue'

import { mountVueComponents } from "~/lib/mount_vue_component";

ActiveStorage.start();

const Toast = defineAsyncComponent(() => import("~/pages/application/toast.vue"))
const Register = defineAsyncComponent(() => import("~/pages/standalone/register.vue"))
const SignUp = defineAsyncComponent(() => import("~/pages/application/sessions/sign_up.vue"))
const Confirmation = defineAsyncComponent(() => import("~/pages/application/sessions/confirmation.vue"))

// TODO: move to zendesk new ui
const ZendeskSetupApiToken = defineAsyncComponent(() => import("~/pages/zendesk/setup_api_token.vue"))

const FreshdeskSetupApiToken = defineAsyncComponent(() => import("~/pages/freshdesk/setup_api_token.vue"))
const ShareVideo = defineAsyncComponent(() => import("~/pages/share/video/index.vue"))

const components = {
  Toast,
  Register,
  SignUp,
  Confirmation,
  ZendeskSetupApiToken,
  FreshdeskSetupApiToken,
  ShareVideo,
}

mountVueComponents(components);

const application = Application.start();
application.debug = process.env.NODE_ENV === 'development';
application.warnings = true;

try {
  const controllers = import.meta.globEager('../controllers/**/*_controller.{js,ts}');
  registerControllers(application, controllers);
} catch (error) {
  console.error(error);
}

window.Turbo = Turbo;
window.application = application;
